function HomePage() {
    return (
        <div className="bg-gradient-to-r flex flex-col h-screen items-center justify-center">
            {" "}
            <div className="header">
                Enter Password
            </div>
            <input>
                coming soon
            </input>
        </div>
    );
}

export default HomePage;
